import { render, staticRenderFns } from "./JSSetting.vue?vue&type=template&id=60d19624"
import script from "./JSSetting.vue?vue&type=script&lang=js"
export * from "./JSSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports