<template>
   <div>
     <CCard class="col-md-12">
      <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">JA</span>VASCRIPT SETTING</h4>
      </CRow>
      <CCardBody class="col-md-12">
          <CRow >
          <CTextarea
            class="col-md-8"
            label="Domain Whitelist"
            size="sm"
            v-model="js.domain_whitelist"
            required
            placeholder="Domain Whitelist"
            :state="validation"
            maxlength="5000"
          ></CTextarea>
          </CRow>
          <CRow class="col-md-10">
          <p style="font-size: 12px;">Please enter a comma separated list of domains. Do NOT include the protocol (http). Example: my.domain.com, company.net, 192.168.1.1</p>
          <p style="font-size: 12px;">List your domains which are allowed to host the fraudAvoid JS snippet. Leave this field blank to allow all domains. If you don't use this feature, anybody can copy your snippet and use it on their pages.</p>
          <p style="font-size: 12px;">This list of domains will take effect the moment you fill it in, so be sure to include all your domains. You can also use IP addresses for test servers.</p>
          
          <!-- <b-form-invalid-feedback
            style="margin-left:160px;"
            :state="validation"
          >Please add a name for the traffic source which will help you identify it later</b-form-invalid-feedback> -->
        </CRow>
        <CRow class="mt-3 col-md-12">
          <CButton
            type="submit"
            v-on:click="onSubmit" color="success"
          >Save</CButton>
        </CRow> 
      </CCardBody></CCard>
   </div> 
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import SettingService from './../../services/settingControlService'
import HelperFunction from "./../../helper/helperFunction";
export default {
    data(){
        return{
            js:{domain_whitelist:''}
        }
    },
    async mounted() {
      if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
      else{
      try {
        let response = await SettingService.jsget()
      if(response.message){
      this.js.domain_whitelist = response.message.domainwhlist.toString()}
    } catch (e) {
      console.log('connection error')
      // this.errorNotify("Error", "connection error", "danger");
    }
    }
    },
    computed: {
    validation() {
    //   if (this.mainObject.offername == "") {
    //     return false;
    //   }
    }
  },
    methods: {
    async onSubmit(){
      if(this.js.domain_whitelist == '' || HelperFunction.validateDomains(this.js.domain_whitelist)){
          let js_data = {
              domainwhlist: this.js.domain_whitelist
          }
          let response = await SettingService.js(js_data)
          if(response.result){
              Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
            }
            else{
              Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
            }
          }
    },
    }
}
</script>
